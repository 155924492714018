import PropTypes from 'prop-types'
import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import FreeTrialButton from './FreeTrialButton'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Section from './Section'

const CriticalData = ({ title, description, image }) => {
  return (
    <Section
      style={{
        width: '100vw',
        background: 'var(--dark)',
        color: 'white',
      }}>
      <Container>
        <Row>
          <Col md={6} data-sal='fade'>
            <h2>{title}</h2>

            <p>{description}</p>

            <div className='text-center text-md-end mb-4'>
              <FreeTrialButton fontSize='1.3rem' />
            </div>
          </Col>

          <Col md={6}>
            <div className='d-flex justify-content-center align-items-center'>
              <PreviewCompatibleImage
                imageInfo={{
                  image: image,
                  alt: `Moolah dashboard`,
                  style: { maxWidth: 400 },
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

CriticalData.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default CriticalData
