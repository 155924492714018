import PropTypes from 'prop-types'
import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import FreeTrialButton from './FreeTrialButton'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Section from './Section'

const DetailedReports = ({ title, description, image }) => {
  return (
    <Section>
      <Container>
        <Row>
          <Col md={6} className='order-2 order-md-1'>
            <div className='d-flex justify-content-center align-items-center'>
              <PreviewCompatibleImage
                imageInfo={{
                  image: image,
                  alt: `Moolah detailed reports`,
                  style: { maxWidth: 500 },
                }}
              />
            </div>
          </Col>

          <Col md={6} className='order-1 order-md-2'>
            <div className='h-100 d-flex align-items-center'>
              <div>
                <h2>{title}</h2>

                <p>{description}</p>

                <div className='text-center text-md-end mb-4'>
                  <FreeTrialButton fontSize='1.3rem' />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

DetailedReports.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default DetailedReports
