import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import IndexPageTemplate from './index-page-template'

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <Seo title='Moolah - Xero reporting made easy' />
      <IndexPageTemplate
        section1={frontmatter.section1}
        section2={frontmatter.section2}
        section3={frontmatter.section3}
        pricingPlans={frontmatter.pricing_plans}
        section4={frontmatter.section4}
        quote={frontmatter.quote}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        section1 {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        section2 {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        section3 {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        pricing_plans {
          title
          description
          plans {
            name
            up_to_invoices
            monthly_price
          }
        }
        section4 {
          title
          description
        }
        quote {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
