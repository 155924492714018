import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Section from './Section'

const Recommended = ({ title, description, image }) => {
  return (
    <Section>
      <Container>
        <Row>
          <Col md={6} style={{ position: 'relative' }} data-sal='fade'>
            <div
              style={{
                position: 'absolute',
                top: -20,
                left: -1,
                zIndex: 1,
                opacity: '0.8',
              }}>
              <StaticImage src='../images/quote.svg' alt='Recommended' />
            </div>

            <div style={{ position: 'relative', marginTop: '56px', zIndex: 9999 }}>
              <p>
                <span className='fs-4 fw-bold'>{title}</span>
                <br />
                {description}
              </p>
            </div>
          </Col>

          <Col md={6}>
            <div className='d-flex justify-content-center align-items-center'>
              <PreviewCompatibleImage
                imageInfo={{
                  image: image,
                  alt: `Moolah and Xero`,
                  style: { maxWidth: 400 },
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

Recommended.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default Recommended
