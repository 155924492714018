import PropTypes from 'prop-types'
import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import ReactSlider from 'react-slider'
import FreeTrialButton from './FreeTrialButton'
import * as styles from './Pricing.module.scss'
import Section from './Section'

const Pricing = ({ pricingPlans }) => {
  const [value, setValue] = React.useState()
  const [orderedPlans, setOrderedPlans] = React.useState([])
  const [minInvoices, setMinInvoices] = React.useState()
  const [maxInvoices, setMaxInvoices] = React.useState()

  React.useEffect(() => {
    const orderedPlans = pricingPlans.plans.sort((a, b) =>
      a.up_to_invoices > b.up_to_invoices ? 1 : -1
    )
    const minInvoices = orderedPlans[0].up_to_invoices
    const maxInvoices = orderedPlans[orderedPlans.length - 2].up_to_invoices + 1

    setOrderedPlans(orderedPlans)
    setValue(orderedPlans[1].up_to_invoices)
    setMinInvoices(minInvoices)
    setMaxInvoices(maxInvoices)
  }, [pricingPlans.plans])

  const getPlan = () => {
    for (const plan of orderedPlans) {
      if (value <= plan.up_to_invoices) {
        return plan
      }
    }

    return 'Contact Us'
  }

  if (!minInvoices || !maxInvoices) return null

  return (
    <Section>
      <Container>
        <Row>
          <Col md={5}>
            <Row>
              <Col md={6} className='text-center'>
                <Card className='w-100 h-100'>
                  <Card.Body>
                    <div>My organisation has</div>

                    <div className='fs-1'>
                      {value > maxInvoices ? `${maxInvoices}+` : value}
                    </div>

                    <div>repeating invoices</div>

                    <ReactSlider
                      className={styles.slider}
                      thumbClassName={styles.thumb}
                      trackClassName={styles.track}
                      min={minInvoices}
                      max={maxInvoices + 1}
                      defaultValue={value}
                      renderTrack={(props, state) => <div {...props} />}
                      onChange={setValue}
                    />
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6}>
                <Card className='w-100 h-100'>
                  <Card.Body>
                    <Card.Title>{getPlan().name} Plan</Card.Title>
                    <Card.Text className={styles.planText}>
                      <div className='mb-2'>
                        £
                        {getPlan().monthly_price.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}{' '}
                        per month
                      </div>
                      <div className='mb-2'>
                        The {getPlan().name} would work best for this quantity of
                        repeating invoices.
                      </div>
                      <div className='mb-2'>
                        <small>You can change your plan at any time!</small>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col md={{ span: 5, offset: 1 }}>
            <h2>{pricingPlans.title}</h2>

            <p>{pricingPlans.description}</p>

            <div className='text-center text-md-end mb-4'>
              <FreeTrialButton fontSize='1.3rem' />
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

Pricing.propTypes = {
  pricingPlans: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        up_to_invoices: PropTypes.string,
        monthly_price: PropTypes.string,
      })
    ),
  }),
}

export default Pricing
