import PropTypes from 'prop-types'
import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import FreeTrialButton from './FreeTrialButton'
import Section from './Section'

const SignInInstantly = ({ title, description }) => {
  return (
    <Section
      style={{
        width: '100vw',
        background: 'var(--gradient-purple)',
        color: 'white',
      }}>
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }} className='text-center' data-sal='fade'>
            <h2>{title}</h2>

            <p>{description}</p>

            <div className='mb-4'>
              <FreeTrialButton fontSize='1.3rem' />
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

SignInInstantly.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SignInInstantly
