import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const PreviewCompatibleImage = ({ imageInfo }) => {
  const image = getImage(imageInfo.image)
  const style = imageInfo.style ?? {}
  style.borderRadius = '6px'

  if (image === undefined) {
    return <img style={imageInfo.style} src={imageInfo.image.url} alt={imageInfo.alt} />
  }

  return <GatsbyImage style={imageInfo.style} image={image} alt={imageInfo.alt} />
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
}

export default PreviewCompatibleImage
