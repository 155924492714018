import PropTypes from 'prop-types'
import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import FreeTrialButton from '../components/FreeTrialButton'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import Section from './Section'

const Hero = ({ title, description, image }) => {
  return (
    <Section
      style={{
        width: '100vw',
        background: 'var(--gradient-blue)',
        color: 'white',
      }}>
      <Container>
        <Row>
          <Col md={6}>
            <div className='h-100 d-flex align-items-center'>
              <div>
                <h1>{title}</h1>

                <p>{description}</p>

                <div className='text-center text-md-end mb-4'>
                  <FreeTrialButton fontSize='1.3rem' />
                </div>
              </div>
            </div>
          </Col>

          <Col md={6}>
            <div className='d-flex justify-content-center align-items-center'>
              <PreviewCompatibleImage
                imageInfo={{
                  image: image,
                  alt: `Moolah`,
                  style: { maxWidth: 400 },
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default Hero
