import PropTypes from 'prop-types'
import React from 'react'
import CriticalData from '../components/CriticalData'
import DetailedReports from '../components/DetailedReports'
import Hero from '../components/Hero'
import Pricing from '../components/Pricing'
import Recommended from '../components/Recommended'
import SignInInstantly from '../components/SignInInstantly'

export const IndexPageTemplate = ({
  section1,
  section2,
  section3,
  pricingPlans,
  section4,
  quote,
}) => (
  <>
    <Hero
      title={section1.title}
      description={section1.description}
      image={section1.image}
    />

    <DetailedReports
      title={section2.title}
      description={section2.description}
      image={section2.image}
    />

    <CriticalData
      title={section3.title}
      description={section3.description}
      image={section3.image}
    />

    <Pricing pricingPlans={pricingPlans} />

    <SignInInstantly title={section4.title} description={section4.description} />

    <Recommended
      title={quote.title}
      description={quote.description}
      image={quote.image}
    />
  </>
)

IndexPageTemplate.propTypes = {
  section1: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  section2: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  section3: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  pricingPlans: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        up_to_invoices: PropTypes.string,
        monthly_price: PropTypes.string,
      })
    ),
  }),
  quote: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

export default IndexPageTemplate
